import { PUBLIC_CARDS } from '../../../constants/jsonData'
import { CARD_HISTORY } from '../../../constants/localStorage'

export default function createCheckedCardsNode(callback, maxLength) {
  // カルーセルとか入れるコンテナ
  const carouselOuter = document.getElementById('cardHistoryOuter')
  if (location.pathname.split('/')[1] !== 'creditcard' || !carouselOuter) {
    return
  }
  const currentCardId = location.pathname.split('/')[3]

  fetchData().then(creditCards => {
    const localStorageDataExist = localStorage.getItem(CARD_HISTORY)
    if (!localStorageDataExist) {
      localStorage.setItem(CARD_HISTORY, [currentCardId])
    }

    const cardIdsFromLocalStorage = [...localStorage.getItem(CARD_HISTORY).split(',')]
    const checkedCardIds = correctCardIds(cardIdsFromLocalStorage, currentCardId, creditCards)
    localStorage.setItem(CARD_HISTORY, checkedCardIds)

    //今見ているカードは表示しないので除外する&&長さが指定されていれば調節する
    const displayCardIds = maxLength
      ? checkedCardIds.filter((id, index) => id !== currentCardId && index <= maxLength)
      : checkedCardIds.filter((id, index) => id !== currentCardId)

    //初めてのアクセスでは何も表示しない
    if (!displayCardIds.length) {
      carouselOuter.parentElement.removeChild(carouselOuter)
      return callback()
    }

    const displayCards = displayCardIds.map(id => creditCards.find(card => card.id === id))
    const displayCardsList = displayCards.map(card => returnListItem(card))

    const loader = carouselOuter.querySelector('.sc21-Loading')
    const carouselInner = carouselOuter.querySelectorAll('[data-history-carousel]')

    if (carouselOuter && loader && carouselInner) {
      loader.parentElement.removeChild(loader)
      carouselInner.forEach(target => (target.innerHTML = displayCardsList.join('')))
      carouselOuter.classList.remove('-hidden')
    }

    callback()
  })
}

async function fetchData() {
  const response = await fetch(PUBLIC_CARDS)
  const data = await response.json()
  return data
}

function correctCardIds(inComingIds, currentCardId, creditCards) {
  const existCardIds = rejectInvalidId(inComingIds, creditCards)
  const validCardIds = [...new Set([currentCardId, ...existCardIds])]
  return validCardIds
}

function rejectInvalidId(ids, cards) {
  const allExistCardIds = cards.map(card => card.id)
  return ids.filter(id => allExistCardIds.includes(id))
}

function wrapLsTag(str) {
  if (!str) return str

  return str.replace(/(<[^>]+>)|([^<>]+)/g, (match, tag, text) => {
    if (tag) {
      return tag
    }

    // テキスト部分を処理
    return text.replace(
      /(&[^;]+;)|(SAISON)|(?<!\w)([a-zA-Z]+)(?!\w)|(?<!\w)(\d+)(?!\w)/g,
      (match, entity, saisonWord, word, number) => {
        // エンティティの場合はそのまま返す
        if (entity) {
          return entity
        }
        // SAISONの場合はそのまま返す
        else if (saisonWord) {
          return saisonWord
        }
        // 単語の場合
        else if (word) {
          return `<span class="sc21-u-ls-m">${word}</span>`
        }
        // 数字の場合
        else if (number) {
          return `<span class="sc21-u-ls-l">${number}</span>`
        }

        // 万が一のため
        return match
      }
    )
  })
}

function returnListItem(card) {
  const cardName = card.name
  wrapLsTag(cardName)
  const cardImage = card.creditCardImages[0].image.url
  const cardOverview = card.cardOverview || ''
  wrapLsTag(cardOverview)
  const cardOutline = card.cardOutline
  wrapLsTag(cardOutline)
  const id = card.id
  const hrefUrl = card.detailPageUrl ? card.detailPageUrl : `/creditcard/lineup/${id}/`
  const external = card.external ? `target="_blank" rel="noopener"` : ''
  const externalLogo = card.external
    ? `<svg width="14" height="10" class="sc21-CreditCardCommonItem-external" role="img" aria-label="（新しいタブで開きます）">
      <use href="#sc21-external"/>
    </svg>`
    : ''
  const brands = returnBrandsHtml(card.cardSpec.creditCardBrands).join('')
  const listItem = `
  <div class="sc21-carousel-item" role="listitem">
    <div class="sc21-CreditCardCommonItem">
      <a href="${hrefUrl}" class="sc21-CreditCardCommonItem-link" ${external}>
        <div class="sc21-CreditCardCommonItem-banner">
          <img src="${cardImage}?auto=compress,format&w=500"
              width="327" height="206" alt="「${cardName}」券面"
              class="sc21-CreditCardCommonItem-image">
        </div>
        <dl class="sc21-CreditCardCommonItem-body">
          <dt class="sc21-CreditCardCommonItem-title">
            ${cardName}${externalLogo}
          </dt>
          <dd class="sc21-CreditCardCommonItem-description">
            ${cardOutline || cardOverview}
          </dd>
          <dd class="sc21-CreditCardCommonItem-brands">
            <ul class="sc21-CreditCardCommonItem-list">
              ${brands}
            </ul>
          </dd>
        </dl>
      </a>
    </div>
  </div>`
  return listItem
}

function returnBrandsHtml(brandsData) {
  const htmlArray = brandsData.map(brand => {
    const image = brand.logo.url
    const alt = brand.name
    const brandList = `
      <li class="sc21-CreditCardCommonItem-item">
        <img src="${image}?auto=compress,format&w=80" width="40" height="40" alt="${alt}" class="sc21-CreditCardCommonItem-brand">
      </li>`
    return brandList
  })
  return htmlArray
}
