import CreditCardReccomendItemsForSP from '../classes/CreditCardReccomendItemsForSP'

const rootSelector = '.sc21-CreditCardReccomendItemsForSP'

function initCreditCardReccomendItemsForSP() {
  const roots = document.querySelectorAll(rootSelector)
  if (!roots || !roots.length) {
    return
  }
  roots.forEach(root => {
    new CreditCardReccomendItemsForSP({ root })
  })
}

export default initCreditCardReccomendItemsForSP
