class ToggleAccordion {
  constructor(props) {
    this.props = props
    this.mediaQuery = window.matchMedia('(max-width: 768px)')
    this.isSP = false

    this.elements = {
      root: this.props.root,
    }

    this.elements.trigger = this.props.root.querySelector(this.props.triggerSelector)
    this.elements.content = this.props.root.querySelector(this.props.contentSelector)
    this.elements.contentWrapper = this.props.root.querySelector(this.props.contentWrapperSelector)

    this.id = this.props.root.dataset.id
    this.column = this.props.root.dataset.column
    this.toggleGroup = this.props.root.dataset.toggleGroup
    this.OPEN_EVENT_NAME = 's21:ToggleAccordion:openAccordion:' + this.toggleGroup

    this.isOpen = this.elements.trigger.getAttribute('aria-expanded') === 'true' ? true : false
    this.onWindowResizeBind = this.onWindowResize.bind(this)

    this.addEventListeners()
    this.checkSP()
    this.addMediaQueryEventListener()
    setTimeout(() => {
      this.setHeight()
    })
  }

  addMediaQueryEventListener() {
    this.mediaQuery.addEventListener('change', () => {
      this.checkSP()
      this.closeAccordion()
    })
  }

  checkSP() {
    if (this.mediaQuery.matches) {
      this.isSP = true
    } else {
      this.isSP = false
    }
  }

  addEventListeners() {
    this.elements.trigger.addEventListener('click', () => {
      this.toggleAccordion()
    })
    document.addEventListener(this.OPEN_EVENT_NAME, event => {
      if (event.detail.toggleGroup === this.toggleGroup) {
        if (event.detail.column === this.column && event.detail.id !== this.id) {
          if (!this.isSP) {
            this.closeAccordion()
          }
        }
      }
    })
  }

  addWindowResizeEventListener() {
    window.addEventListener('resize', this.onWindowResizeBind)
  }

  removeWindowResizeEventListener() {
    window.removeEventListener('resize', this.onWindowResizeBind)
  }

  toggleAccordion() {
    const nextState = !this.isOpen

    if (nextState) {
      this.openAccordion()
    } else {
      this.closeAccordion()
    }

    if (this.isOpen) {
      this.addWindowResizeEventListener()
    } else {
      this.removeWindowResizeEventListener()
    }
  }

  openAccordion() {
    this.isOpen = true
    this.elements.trigger.setAttribute('aria-expanded', 'true')
    this.setHeight()

    document.dispatchEvent(
      new CustomEvent(this.OPEN_EVENT_NAME, {
        detail: {
          toggleGroup: this.toggleGroup,
          id: this.id,
          column: this.column,
        },
      })
    )
  }

  closeAccordion() {
    this.isOpen = false
    this.elements.trigger.setAttribute('aria-expanded', 'false')
    this.setHeight()
  }

  setHeight() {
    this.elements.content.style.height = this.isOpen ? `${this.elements.contentWrapper.offsetHeight}px` : '0px'
  }

  onWindowResize() {
    if (this.isOpen) {
      this.setHeight()
    }
  }
}

export default ToggleAccordion
