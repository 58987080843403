import gsap from 'gsap'

export default class CreditCardReccomendItemsForSP {
  constructor(props) {
    this.props = {
      ...props,
    }

    const { root } = this.props

    this.resizeIntervalId = null
    this.list = root.querySelector('.sc21-CreditCardReccomendItemsForSP-list')
    this.listHeight = this.list.clientHeight

    this.hiddenList = root.querySelector('.sc21-CreditCardReccomendItemsForSP-hidden-list')
    this.button = root.querySelector('.sc21-CreditCardReccomendItemsForSP-more')

    this.button.addEventListener('click', e => {
      e.preventDefault()
      this.updateList()
    })

    window.addEventListener('resize', () => {
      clearInterval(this.resizeIntervalId)
      this.resizeIntervalId = window.setTimeout(() => {
        this.animateList({
          duration: 0,
        })
      }, 100)
    })
  }

  getHiddenItems() {
    const items = Array.from(this.hiddenList.querySelectorAll('.sc21-CreditCardRecommendItem'))
    //3つだけ
    return items.slice(0, 3)
  }

  checkExistHiddenItems() {
    const items = Array.from(this.hiddenList.querySelectorAll('.sc21-CreditCardRecommendItem'))
    return items.length > 0
  }

  getRealListHeight() {
    this.list.style.removeProperty('height')
    const height = this.list.clientHeight
    return height
  }

  updateList() {
    const hiddenItems = this.getHiddenItems()
    if (!hiddenItems || !hiddenItems.length) {
      return
    }

    for (const item of hiddenItems) {
      this.list.appendChild(item)
      item.style.opacity = 0
    }

    //
    this.animateList({ hiddenItems, duration: 0.8 })

    //
    if (!this.checkExistHiddenItems()) {
      this.button.setAttribute('aria-disabled', 'true')
      gsap.to(this.button, {
        opacity: 0,
        duration: 0.4,
        ease: 'power2.inOut',
        onComplete: () => {
          this.button.style.display = 'none'
        },
      })
    }
  }

  animateList({ hiddenItems = null, duration = 0.8 }) {
    const realListHeight = this.getRealListHeight()
    gsap.to(this, {
      listHeight: realListHeight,
      duration: duration,
      ease: 'power2.inOut',
      onUpdate: () => {
        this.updateListHeight()
      },
      onComplete: () => {
        this.updateListHeight()
      },
    })
    if (hiddenItems) {
      hiddenItems.forEach(item => {
        gsap.to(item, {
          opacity: 1,
          duration: duration,
          ease: 'power2.inOut',
        })
      })
    }
  }

  updateListHeight() {
    this.list.style.height = `${this.listHeight}px`
  }
}
