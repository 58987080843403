// polyfills
import 'core-js/stable'
import 'custom-event-polyfill'
import 'wicg-inert/dist/inert'
import smoothscroll from 'smoothscroll-polyfill'

// util
import detectSupportPictureElement from './utils/detectSupportPictureElement'

// modules
import scrollableChecker from './common/scrollableChecker'
import searchFormExpander from './common/searchFormExpander'
import initCarousel from './common/initCarousel'
import './common/initSwiper'
import initCreditCardDetailStickies from './common/initCreditCardDetailStickies'
import initYouTubePlayer from './common/initYouTubePlayer'
import initChangeCardFace from './common/initChangeCardFace'
import './common/initChangeCardFaceLite'
import initDisclosure from './common/initDisclosure'
import initGlobalHeader from './common/initGlobalHeader'
import initGlobalFooter from './common/initGlobalFooter'
import initScrollableTableHint from './common/initScrollableTableHint'
import createCheckedCardsNode from './common/createCheckedCardsNode'
import setCustomPropertyVh from './common/setCustomPropertyVh'
import initAccordion from './common/initAccordion'
import initTabUI from './common/initTabUI'
import scrollBehaviorPolyfill from './common/scroll-behavior-polyfill'
import './common/homeSlider'
import initToggleAccordion from './common/initToggleAccordion'
import initCreditCardReccomendItemsForSP from './common/initCreditCardReccomendItemsForSP'

setCustomPropertyVh()
smoothscroll.polyfill()
scrollBehaviorPolyfill()

// picture要素が使えるかどうかの判定をして、使えなかったらHTML要素にクラス名をつける
detectSupportPictureElement()

scrollableChecker({
  targetSelector: '.sc21-js-scrollableCheck',
  targetChildSelector: '.sc21-js-scrollableCheckContent',
})

initGlobalHeader()
initGlobalFooter()
searchFormExpander()
initCreditCardDetailStickies()
initYouTubePlayer()
initChangeCardFace()
initDisclosure()
initScrollableTableHint()
initAccordion()
initTabUI()
initToggleAccordion()
initCreditCardReccomendItemsForSP()

const cardDetailPageRegExp = /^\/creditcard\/lineup\/.*/
if (location.pathname.match(cardDetailPageRegExp)) {
  createCheckedCardsNode(initCarousel, 4)
} else {
  initCarousel()
}
