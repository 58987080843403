import ToggleAccordion from '../classes/ToggleAccordion'

function initToggleAccordion() {
  const toggleAccordionRoots = document.querySelectorAll('.sc21-toggleAccordion')
  toggleAccordionRoots.forEach(root => {
    new ToggleAccordion({
      root,
      triggerSelector: '.sc21-toggleAccordion-trigger',
      contentSelector: '.sc21-toggleAccordion-content',
      contentWrapperSelector: '.sc21-toggleAccordion-contentWrapper',
    })
  })
}

export default initToggleAccordion
