import Splide from '@splidejs/splide'

class HomeSlider {
  constructor($el) {
    // read only props
    Object.defineProperty(this, '$el', { value: $el })
    Object.defineProperty(this, '$slideList', { value: $el.querySelectorAll('.sc21-homeSlider-slide') })
    Object.defineProperty(this, '$paginationItemList', {
      value: $el.querySelectorAll('.sc21-homeSlider-paginationItem'),
    })
    Object.defineProperty(this, '$paginationButtonList', {
      value: $el.querySelectorAll('.sc21-homeSlider-paginationButton'),
    })

    this.$splide = $el.querySelector('.splide')
    this.$splideSlides = $el.querySelectorAll('.splide__slide')
    this.$prevButton = $el.querySelector('.sc21-homeSlider-pagerButton.-prev')
    this.$nextButton = $el.querySelector('.sc21-homeSlider-pagerButton.-next')

    Object.defineProperty(this, 'itemLength', { value: this.$splideSlides.length })
    Object.defineProperty(this, 'duration', { value: 5000 })

    this.mql = window.matchMedia('screen and (max-width: 767px)')

    if (this.itemLength === 0) {
      return
    }

    this._timeoutId = 0
    this._running = false
    this._currentIndex = 0

    this.$prevButton.addEventListener('click', this.toPrev.bind(this))
    this.$nextButton.addEventListener('click', this.toNext.bind(this))

    //update 2025.02
    this.pagerDotList = []
    this.$pagerDotsWrapper = $el.querySelector('.sc21-homeSlider-pagerDots')

    this.$pagerDots = Array.from($el.querySelectorAll('.sc21-homeSlider-pagerDot'))
    this.$pagerDots.forEach(($pagerDot, i) => {
      this.pagerDotList.push(new HomeSliderPagerDot($pagerDot, i))
      $pagerDot.addEventListener('click', event => {
        event.preventDefault()
        this.to(i)
      })
    })
    this.currentPagerDot = this.pagerDotList[this._currentIndex]
    this._boundOnPagerDotCountEnd = this._onPagerDotCountEnd.bind(this)

    this.$pauseButton = $el.querySelector('.sc21-homeSlider-pauseButton')
    this.$pauseButton.addEventListener('click', () => {
      this._running ? this.stop() : this.play()
    })

    try {
      this.mql.addEventListener('change', this.onChangeMql.bind(this))
    } catch (e) {
      this.mql.addListener(this.onChangeMql.bind(this))
    }
    this.onChangeMql(this.mql)

    this.setupSplide()
    this.play()
    // this.addSwipeEventListeners()
  }

  setupSplide() {
    this.isSplideDragging = false
    this.splide = new Splide(this.$splide, {
      type: 'loop',
      perPage: 1,
      perMove: 1,
      arrows: false,
      pagination: false,
    })
    this.splide.mount()
    this.splide.on('move', this.onSplideMove.bind(this))
    this.splide.on('moved', this.onSplideMoved.bind(this))
    this.splide.on('dragging', this.onSplideDragging.bind(this))
    this.splide.on('dragged', this.onSplideDragEnd.bind(this))
  }

  to(index) {
    this.splide.go(`>${index}`)
  }

  onSplideMove(index) {
    this._currentIndex = index
    this._updatePagerDots()
  }

  onSplideMoved(index) {
    this._currentIndex = index
    this._updatePagerDots()
  }

  onSplideDragging() {
    this.isSplideDragging = true
    this.pagerDotList.forEach(pagerDot => {
      pagerDot.updateDragging(this.isSplideDragging)
    })
  }

  onSplideDragEnd() {
    this.isSplideDragging = false
    this.pagerDotList.forEach(pagerDot => {
      pagerDot.updateDragging(this.isSplideDragging)
    })
  }

  toNext() {
    this.splide.go('>')
  }

  toPrev() {
    this.splide.go('<')
  }

  stop() {
    if (!this._running) return

    this._running = false
    clearTimeout(this._timeoutId)
    this._timeoutId = 0
    this._updateClassNames()
    this._pausePagerDots()
  }

  play() {
    if (this._running) return

    this._running = true
    this._transition()
    this._updatePagerDots()
    this._updateClassNames()
    this._resumePagerDots()
  }

  _transition() {
    this._updatePagerDots()
    this._updateSlider()
  }

  _updatePagerDots() {
    this._removeAllPagerEventListeners()

    this.pagerDotList.forEach((pagerDot, index) => {
      if (index !== this._currentIndex) {
        pagerDot.deactivate()
      }
    })

    this.currentPagerDot = this.pagerDotList[this._currentIndex]
    this.currentPagerDot.$el.addEventListener('countEnd', this._boundOnPagerDotCountEnd)
    this.currentPagerDot.activate()
  }

  _pausePagerDots() {
    this.pagerDotList.forEach(pagerDot => {
      pagerDot.pause()
    })
  }

  _resumePagerDots() {
    this.pagerDotList.forEach(pagerDot => {
      pagerDot.resume()
    })
  }

  _updateSlider() {
    this.splide.go(`>${this._currentIndex}`)
  }

  _onPagerDotCountEnd() {
    this._removeAllPagerEventListeners()
    if (this._running) {
      this.toNext()
    }
  }

  _removeAllPagerEventListeners() {
    this.pagerDotList.forEach(pagerDot => {
      pagerDot.$el.removeEventListener('countEnd', this._boundOnPagerDotCountEnd)
    })
  }

  _updateClassNames() {
    if (this._running) {
      this.$el.classList.add('-running')
      // this.$stopButton.innerHTML = '一時停止'
      this.$pauseButton.classList.remove('-pause')
      this.$pauseButton.setAttribute('aria-label', this.$pauseButton.getAttribute('data-aria-label-pause'))
    } else {
      this.$el.classList.remove('-running')
      // this.$stopButton.innerHTML = '再生'
      this.$pauseButton.classList.add('-pause')
      this.$pauseButton.setAttribute('aria-label', this.$pauseButton.getAttribute('data-aria-label-play'))
    }
  }

  _updatePagerDotsWrapperWidth() {
    this.$pagerDotsWrapper.style.removeProperty('width')
    this.$pagerDotsWrapper.style.width = this.$pagerDotsWrapper.getBoundingClientRect().width + 'px'
  }

  onChangeMql(mql) {
    this._updatePagerDotsWrapperWidth()
  }
}

//update 2025.02
class HomeSliderPagerDot {
  constructor($el, index) {
    this.$el = $el
    this.index = index
    this.isSliderDragging = false

    this.PLUS_COUNT = 1
    this.MAX_COUNT = 5000
    this.isPause = false
    this.$indicator = this.$el.querySelector('.sc21-homeSlider-pagerDotIndicator')
    this.isCurrent = this.$el.classList.contains('-current')
    this.animationFrameId = null
    this.count = 0
    this.lastTime = null
  }

  activate() {
    this.isCurrent = true
    this.$el.classList.add('-current')
    this.start()
  }

  deactivate() {
    this.count = 0
    this.isCurrent = false
    this.$el.classList.remove('-current')
    this.clearCount()
  }

  start() {
    this.clearAnimationFrame()
    // this.count = 0
    this.lastTime = null
    this.animate()
  }

  animate(timestamp) {
    if (this.isSliderDragging) return
    if (this.isPause) return

    if (!this.lastTime) this.lastTime = timestamp
    const delta = timestamp - this.lastTime || 0
    this.lastTime = timestamp

    // console.log('animate')
    // console.log(timestamp)

    // delta に応じたカウントアップ（例: 1msあたり PLUS_COUNT）
    this.changeCount(this.count + this.PLUS_COUNT * delta)

    if (this.count >= this.MAX_COUNT) {
      this.onCountEnd()
      return // ここでループ終了
    }

    this.animationFrameId = window.requestAnimationFrame(this.animate.bind(this))
  }

  pause() {
    this.isPause = true
    this.count = 0
    this.clearAnimationFrame()
    this.updateView()
  }

  resume() {
    this.isPause = false
    if (this.isCurrent) {
      this.lastTime = null
      this.animate()
    }
  }

  clearCount() {
    this.changeCount(0)
    this.clearAnimationFrame()
  }

  clearAnimationFrame() {
    if (this.animationFrameId) {
      window.cancelAnimationFrame(this.animationFrameId)
      this.animationFrameId = null
    }
  }

  changeCount(count) {
    this.count = count
    this.updateView()
  }

  updateView() {
    let percent = (this.count / this.MAX_COUNT) * 100

    // console.log('--------------------------------')
    // console.log(this.count)
    // console.log(this.MAX_COUNT)
    // console.log('--------------------------------')
    if (this.isPause) percent = 100
    this.$indicator.style.width = `${percent}%`
  }

  updateDragging(dragging) {
    this.isSliderDragging = dragging
  }

  onCountEnd() {
    this.clearCount()
    this.$el.dispatchEvent(new CustomEvent('countEnd'))
  }
}

Array.prototype.forEach.call(document.querySelectorAll('.sc21-homeSlider'), $el => new HomeSlider($el))
